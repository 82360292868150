import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Noah Wong",
  "date": "2020-02-16 UTC -8",
  "length": 8,
  "location": "Portland, Oregon",
  "path": "/personalized-email/",
  "title": "Why and How to Setup Personalized Email",
  "tags": ["tech", "email"],
  "description": "Using a custom email and paid email service."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I was tired of handing out my perosnal email address `}{`—`}{` junk mail galore. There are literally hundreds of services or brands that are on constantly asking for your email address to set up an account or a way to constantly bombard you with marketing messaages. Don't you hate it when you're at the local ShoeSource (that's right, I said it `}{`—`}{` ShoeSource) and they automatically ask you for your email address as if they need it to continue the transaction. Yea yea, you may receive some decent coupons out of it but I'm reluctant to just hand out my persoanl email address knowing they could potentially resell my email to the highest bidder.`}</p>
    <h3>{`Step 1: Choose a Domain`}</h3>
    <p>{`You must first choose a domain to your liking. You can either use an existing domain that you own or a choosing an available domain designated (i.e. <chooseyourdomain.com`}{`>`}{`) just for your emails. There are dozens of registrars where you can purchase a domain. `}<a parentName="p" {...{
        "href": "https://www.namecheap.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Namecheap`}</a>{` is the registrar that I've been using recently and they have decent pricing `}{`—`}{` hence the name.`}</p>
    <h3>{`Step 2: Signup for an Email Service`}</h3>
    <p>{`Now I know what some of you may be saying, "why should I start paying for email?" I was tired of handing out my email address `}{`—`}{` junk mail galore. There are literally hundreds of services or brands that are on constantly asking for your email address to set up an account or a way to constantly bombard you with marketing messaages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      